var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"658e3608e2944cb4f4539fc84da9b95e6765b773"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { commonConfig } from '@bridebook/toolbox/src/sentry/sentry-client-config';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  ...commonConfig,
  integrations: [
    new Sentry.BrowserTracing({
      // Need to be more specific about localhost as by default it was checking the whole url for the 'localhost' string and it was attaching extra headers to the requests
      tracePropagationTargets: [/^https:\/\/localhost/, /^http:\/\/localhost/, /^\//],
    }),
  ],
});
